import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import footer from './footer.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import shipmentInfo from './shipmentInfo.mjs'
import inputTypes from './inputTypes.mjs'
import icons from './icons.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...footer,
  ...termsPages,
  ...paymentCodes,
  ...shipmentInfo,
  ...inputTypes,
  companyDetailFieldsOrder: {
    crn: 4,
    vatId: 1,
  },
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'PD',
      topic: agreementsTopic,
      value: 'N',
    },
    {
      channel: 'STAFFINO',
      topic: 'STAFFINO',
      value: 'N',
    },
  ],
  firstPartySellerId: '0',
  firstPartySellerName: 'Dr. Max Apteka',
  paymentFailedMessageComponent: 'simple',
  phoneCodeRestrictions: {},
  shippingInfoBlocks: '',
  thankYouText: {
    payment: 'email',
    dispatch: 'email',
  },
  ...config,
  ...icons,
})
