import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b30644fe = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/index.vue' /* webpackChunkName: "pages/pharmacies/index" */))
const _ed10c186 = () => interopDefault(import('../app/common/modules/articles/pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _3aef5743 = () => interopDefault(import('../app/common/modules/brands/pages/brand-preview.vue' /* webpackChunkName: "pages/brand-preview" */))
const _cac50144 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0f680562 = () => interopDefault(import('../app/common/modules/cms/pages/cms-entity-preview.vue' /* webpackChunkName: "pages/cms-entity-preview" */))
const _926d8ac4 = () => interopDefault(import('../app/common/modules/brands/pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _960a888c = () => interopDefault(import('../layers/my-account/app/pages/my-account.vue' /* webpackChunkName: "pages/my-account" */))
const _491422bb = () => interopDefault(import('../layers/my-account/app/pages/my-account/baby-club/index.vue' /* webpackChunkName: "pages/my-account-baby-club" */))
const _41d60ebd = () => interopDefault(import('../layers/my-account/app/pages/my-account/index.vue' /* webpackChunkName: "pages/my-account" */))
const _4b139d70 = () => interopDefault(import('../layers/my-account/app/pages/my-account/my-profile/index.vue' /* webpackChunkName: "pages/my-account-my-profile" */))
const _0fa0a2f4 = () => interopDefault(import('../layers/my-account/app/pages/my-account/my-offers/index.vue' /* webpackChunkName: "pages/my-account-my-offers" */))
const _6abd1654 = () => interopDefault(import('../layers/my-account/app/pages/my-account/my-orders/index.vue' /* webpackChunkName: "pages/my-account-my-orders" */))
const _784ab19e = () => interopDefault(import('../layers/my-account/app/pages/my-account/my-favorites/index.vue' /* webpackChunkName: "pages/my-account-my-favorites" */))
const _0786df86 = () => interopDefault(import('../layers/my-account/app/pages/my-account/my-alerts/index.vue' /* webpackChunkName: "pages/my-account-my-alerts" */))
const _62793ce9 = () => interopDefault(import('../layers/my-account/app/pages/my-account/purchase/[orderId]/index.vue' /* webpackChunkName: "pages/my-account-purchase-orderId" */))
const _934790de = () => interopDefault(import('../layers/my-account/app/pages/my-account/return/[id].vue' /* webpackChunkName: "pages/my-account-return-id" */))
const _b4181a14 = () => interopDefault(import('../layers/my-account/app/pages/my-account/purchase/[orderId]/new-return.vue' /* webpackChunkName: "pages/my-account-purchase-orderId-new-return" */))
const _2e76518c = () => interopDefault(import('../layers/my-account/app/pages/my-account/purchase/[orderId]/new-return/step1.vue' /* webpackChunkName: "pages/my-account-purchase-orderId-new-return-step1" */))
const _2e84690d = () => interopDefault(import('../layers/my-account/app/pages/my-account/purchase/[orderId]/new-return/step2.vue' /* webpackChunkName: "pages/my-account-purchase-orderId-new-return-step2" */))
const _2e92808e = () => interopDefault(import('../layers/my-account/app/pages/my-account/purchase/[orderId]/new-return/step3.vue' /* webpackChunkName: "pages/my-account-purchase-orderId-new-return-step3" */))
const _16be410c = () => interopDefault(import('../layers/my-account/app/pages/my-account-public.vue' /* webpackChunkName: "pages/my-account-public" */))
const _51fe885a = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/amazon/index.vue' /* webpackChunkName: "pages/my-account-public-amazon" */))
const _aaff9a82 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/competition/index.vue' /* webpackChunkName: "pages/my-account-public-competition" */))
const _9dd67334 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/email-communication-unsubscribe.vue' /* webpackChunkName: "pages/my-account-public-email-communication-unsubscribe" */))
const _2c0ee13f = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/email-verification.vue' /* webpackChunkName: "pages/my-account-public-email-verification" */))
const _bed268c6 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/logout.vue' /* webpackChunkName: "pages/my-account-public-logout" */))
const _72390de0 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/new-return.vue' /* webpackChunkName: "pages/my-account-public-new-return" */))
const _fae947b4 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/new-return/step1.vue' /* webpackChunkName: "pages/my-account-public-new-return-step1" */))
const _facd18b2 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/new-return/step2.vue' /* webpackChunkName: "pages/my-account-public-new-return-step2" */))
const _fab0e9b0 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/new-return/step3.vue' /* webpackChunkName: "pages/my-account-public-new-return-step3" */))
const _63d3e70c = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration.vue' /* webpackChunkName: "pages/my-account-public-registration" */))
const _754f45bc = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration/step1.vue' /* webpackChunkName: "pages/my-account-public-registration-step1" */))
const _753316ba = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration/step2.vue' /* webpackChunkName: "pages/my-account-public-registration-step2" */))
const _7516e7b8 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration/step3.vue' /* webpackChunkName: "pages/my-account-public-registration-step3" */))
const _74fab8b6 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration/step4.vue' /* webpackChunkName: "pages/my-account-public-registration-step4" */))
const _4daf0cae = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration-sorp/index.vue' /* webpackChunkName: "pages/my-account-public-registration-sorp" */))
const _93860d80 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/returns.vue' /* webpackChunkName: "pages/my-account-public-returns" */))
const _c97e33ea = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/set-token.vue' /* webpackChunkName: "pages/my-account-public-set-token" */))
const _273da384 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/unsubscribe-confirmation.vue' /* webpackChunkName: "pages/my-account-public-unsubscribe-confirmation" */))
const _6dcba9d8 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/favorites.vue' /* webpackChunkName: "pages/my-account-public-favorites" */))
const _8b445ed0 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/competition/thankyou.vue' /* webpackChunkName: "pages/my-account-public-competition-thankyou" */))
const _64be65b6 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/loyalty-card/choose-card-type.vue' /* webpackChunkName: "pages/my-account-public-loyalty-card-choose-card-type" */))
const _13d4b087 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/loyalty-card/first-login.vue' /* webpackChunkName: "pages/my-account-public-loyalty-card-first-login" */))
const _54ff4320 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/loyalty-card/step1.vue' /* webpackChunkName: "pages/my-account-public-loyalty-card-step1" */))
const _552989a3 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/loyalty-card/step4.vue' /* webpackChunkName: "pages/my-account-public-loyalty-card-step4" */))
const _5537a124 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/loyalty-card/step5.vue' /* webpackChunkName: "pages/my-account-public-loyalty-card-step5" */))
const _700b4bd2 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/registration-sorp/final-step.vue' /* webpackChunkName: "pages/my-account-public-registration-sorp-final-step" */))
const _8ad54b22 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/reset-password/index.vue' /* webpackChunkName: "pages/my-account-public-reset-password" */))
const _732b1c50 = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/new-password/[hash].vue' /* webpackChunkName: "pages/my-account-public-new-password-hash" */))
const _b262982e = () => interopDefault(import('../layers/my-account/app/pages/my-account-public/email-confirmation/[token].vue' /* webpackChunkName: "pages/my-account-public-email-confirmation-token" */))
const _6ec5937c = () => interopDefault(import('../layers/subscription/app/pages/newsletter-subscriber-confirm.vue' /* webpackChunkName: "pages/newsletter-subscriber-confirm" */))
const _3ca5c49a = () => interopDefault(import('../layers/special-offers/app/pages/special-offers.vue' /* webpackChunkName: "pages/special-offers" */))
const _18439b52 = () => interopDefault(import('../app/common/modules/cms/pages/pagebuilder-preview.vue' /* webpackChunkName: "pages/pagebuilder-preview" */))
const _30883173 = () => interopDefault(import('../app/common/modules/article-preview/pages/post-preview.vue' /* webpackChunkName: "pages/post-preview" */))
const _27f0e77b = () => interopDefault(import('../app/common/modules/product-preview/pages/product-preview.vue' /* webpackChunkName: "pages/product-preview" */))
const _6ced5f1d = () => interopDefault(import('../layers/tags/app/pages/tags/index.vue' /* webpackChunkName: "pages/tags" */))
const _758cfae0 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/index.vue' /* webpackChunkName: "pages/askPharmacist/index" */))
const _1bf54b99 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/thankyou.vue' /* webpackChunkName: "pages/checkout/thankyou" */))
const _1e8c0b67 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-redirect.vue' /* webpackChunkName: "pages/checkout/payment-redirect" */))
const _6d83b008 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-failed.vue' /* webpackChunkName: "pages/checkout/payment-failed" */))
const _6b4c1b8b = () => interopDefault(import('../app/common/modules/checkout/pages/order/cancelled.vue' /* webpackChunkName: "pages/order/cancelled" */))
const _510c7e43 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/pharmacies.vue' /* webpackChunkName: "pages/askPharmacist/pharmacies" */))
const _24028d05 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/callCenter.vue' /* webpackChunkName: "pages/askPharmacist/callCenter" */))
const _c634f154 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionList.vue' /* webpackChunkName: "pages/askPharmacist/questionList" */))
const _68ec607c = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionForm.vue' /* webpackChunkName: "pages/askPharmacist/questionForm" */))
const _3ef9471f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/our-dermocenters.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/our-dermocenters" */))
const _74224362 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/social-networks.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/social-networks" */))
const _45781064 = () => interopDefault(import('../layers/multi-app/layers/pl-apteka/app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _42d40a9f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/_dermocenter/index.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/_dermocenter/index" */))
const _18407904 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/_pharmacy/index.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/index" */))
const _3f61ff56 = () => interopDefault(import('../app/common/modules/articles/pages/authors/_author.vue' /* webpackChunkName: "pages/authors/_author" */))
const _254a01b7 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/_step.vue' /* webpackChunkName: "pages/checkout/_step" */))
const _040cbcae = () => interopDefault(import('../app/common/modules/checkout/pages/repay/_hash.vue' /* webpackChunkName: "pages/repay/_hash" */))
const _23b0df29 = () => interopDefault(import('../layers/tags/app/pages/tags/[tag].vue' /* webpackChunkName: "pages/tags-tag" */))
const _229fd283 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/_question.vue' /* webpackChunkName: "pages/askPharmacist/_question" */))
const _7fe3d023 = () => interopDefault(import('../app/common/modules/catalog/pages/promotion.vue' /* webpackChunkName: "pages/promotion" */))
const _be9fffc4 = () => interopDefault(import('../app/common/modules/search/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _242aae65 = () => interopDefault(import('../app/common/modules/base/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'isCurrent',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apteki",
    component: _b30644fe,
    name: "pharmacies___pl-apteka"
  }, {
    path: "/autorzy",
    component: _ed10c186,
    name: "authors___pl-apteka"
  }, {
    path: "/brand-preview",
    component: _3aef5743,
    name: "brand-preview___pl-apteka"
  }, {
    path: "/checkout",
    component: _cac50144,
    name: "checkout___pl-apteka"
  }, {
    path: "/cms-entity-preview",
    component: _0f680562,
    name: "cms-entity-preview___pl-apteka"
  }, {
    path: "/marki",
    component: _926d8ac4,
    name: "brands___pl-apteka"
  }, {
    path: "/moje-konto",
    component: _960a888c,
    children: [{
      path: "baby-club",
      component: _491422bb,
      name: "my-account-baby-club___pl-apteka",
      children: []
    }, {
      path: "/moje-konto",
      component: _41d60ebd,
      name: "my-account___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/edycja-danych",
      component: _4b139d70,
      name: "my-account-my-profile___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/moje-oferty",
      component: _0fa0a2f4,
      name: "my-account-my-offers___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/moje-zamowienia",
      component: _6abd1654,
      name: "my-account-my-orders___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/ulubione-produkty",
      component: _784ab19e,
      name: "my-account-my-favorites___pl-apteka",
      children: []
    }, {
      path: "/my-account/powiadomienia-o-produktach",
      component: _0786df86,
      name: "my-account-my-alerts___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/zakup/:orderId",
      component: _62793ce9,
      name: "my-account-purchase-orderId___pl-apteka",
      children: []
    }, {
      path: "return/:id?",
      component: _934790de,
      name: "my-account-return-id___pl-apteka",
      children: []
    }, {
      path: "purchase/:orderId?/new-return",
      component: _b4181a14,
      name: "my-account-purchase-orderId-new-return___pl-apteka",
      children: [{
        path: "step1",
        component: _2e76518c,
        name: "my-account-purchase-orderId-new-return-step1___pl-apteka",
        children: []
      }, {
        path: "step2",
        component: _2e84690d,
        name: "my-account-purchase-orderId-new-return-step2___pl-apteka",
        children: []
      }, {
        path: "step3",
        component: _2e92808e,
        name: "my-account-purchase-orderId-new-return-step3___pl-apteka",
        children: []
      }]
    }]
  }, {
    path: "/my-account-public",
    component: _16be410c,
    name: "my-account-public___pl-apteka",
    children: [{
      path: "amazon",
      component: _51fe885a,
      name: "my-account-public-amazon___pl-apteka",
      children: []
    }, {
      path: "competition",
      component: _aaff9a82,
      name: "my-account-public-competition___pl-apteka",
      children: []
    }, {
      path: "email-communication-unsubscribe",
      component: _9dd67334,
      name: "my-account-public-email-communication-unsubscribe___pl-apteka",
      children: []
    }, {
      path: "email-verification",
      component: _2c0ee13f,
      name: "my-account-public-email-verification___pl-apteka",
      children: []
    }, {
      path: "logout",
      component: _bed268c6,
      name: "my-account-public-logout___pl-apteka",
      children: []
    }, {
      path: "new-return",
      component: _72390de0,
      name: "my-account-public-new-return___pl-apteka",
      children: [{
        path: "step1",
        component: _fae947b4,
        name: "my-account-public-new-return-step1___pl-apteka",
        children: []
      }, {
        path: "step2",
        component: _facd18b2,
        name: "my-account-public-new-return-step2___pl-apteka",
        children: []
      }, {
        path: "step3",
        component: _fab0e9b0,
        name: "my-account-public-new-return-step3___pl-apteka",
        children: []
      }]
    }, {
      path: "registration",
      component: _63d3e70c,
      name: "my-account-public-registration___pl-apteka",
      children: [{
        path: "/moje-konto/rejestracja/krok-1",
        component: _754f45bc,
        name: "my-account-public-registration-step1___pl-apteka",
        children: []
      }, {
        path: "/moje-konto/rejestracja/krok-2",
        component: _753316ba,
        name: "my-account-public-registration-step2___pl-apteka",
        children: []
      }, {
        path: "/moje-konto/rejestracja/krok-3",
        component: _7516e7b8,
        name: "my-account-public-registration-step3___pl-apteka",
        children: []
      }, {
        path: "/moje-konto/rejestracja/krok-4",
        component: _74fab8b6,
        name: "my-account-public-registration-step4___pl-apteka",
        children: []
      }]
    }, {
      path: "registration-sorp",
      component: _4daf0cae,
      name: "my-account-public-registration-sorp___pl-apteka",
      children: []
    }, {
      path: "returns",
      component: _93860d80,
      name: "my-account-public-returns___pl-apteka",
      children: []
    }, {
      path: "set-token",
      component: _c97e33ea,
      name: "my-account-public-set-token___pl-apteka",
      children: []
    }, {
      path: "unsubscribe-confirmation",
      component: _273da384,
      name: "my-account-public-unsubscribe-confirmation___pl-apteka",
      children: []
    }, {
      path: "/ulubione-produkty",
      component: _6dcba9d8,
      name: "my-account-public-favorites___pl-apteka",
      children: []
    }, {
      path: "competition/thankyou",
      component: _8b445ed0,
      name: "my-account-public-competition-thankyou___pl-apteka",
      children: []
    }, {
      path: "loyalty-card/choose-card-type",
      component: _64be65b6,
      name: "my-account-public-loyalty-card-choose-card-type___pl-apteka",
      children: []
    }, {
      path: "loyalty-card/first-login",
      component: _13d4b087,
      name: "my-account-public-loyalty-card-first-login___pl-apteka",
      children: []
    }, {
      path: "loyalty-card/step1",
      component: _54ff4320,
      name: "my-account-public-loyalty-card-step1___pl-apteka",
      children: []
    }, {
      path: "loyalty-card/step4",
      component: _552989a3,
      name: "my-account-public-loyalty-card-step4___pl-apteka",
      children: []
    }, {
      path: "loyalty-card/step5",
      component: _5537a124,
      name: "my-account-public-loyalty-card-step5___pl-apteka",
      children: []
    }, {
      path: "registration-sorp/final-step",
      component: _700b4bd2,
      name: "my-account-public-registration-sorp-final-step___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/reset-hasla",
      component: _8ad54b22,
      name: "my-account-public-reset-password___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/nowe-haslo/:hash",
      component: _732b1c50,
      name: "my-account-public-new-password-hash___pl-apteka",
      children: []
    }, {
      path: "/moje-konto/potwierdzenie/:token",
      component: _b262982e,
      name: "my-account-public-email-confirmation-token___pl-apteka",
      children: []
    }]
  }, {
    path: "/newsletter-subscriber-confirm",
    component: _6ec5937c,
    name: "newsletter-subscriber-confirm___pl-apteka",
    children: []
  }, {
    path: "/oferty-specjalne",
    component: _3ca5c49a,
    name: "special-offers___pl-apteka",
    children: []
  }, {
    path: "/pagebuilder-preview",
    component: _18439b52,
    name: "pagebuilder-preview___pl-apteka"
  }, {
    path: "/post-preview",
    component: _30883173,
    name: "post-preview___pl-apteka"
  }, {
    path: "/product-preview",
    component: _27f0e77b,
    name: "product-preview___pl-apteka"
  }, {
    path: "/tagi",
    component: _6ced5f1d,
    name: "tags___pl-apteka",
    children: []
  }, {
    path: "/zapytaj-farmaceute",
    component: _758cfae0,
    name: "askPharmacist___pl-apteka"
  }, {
    path: "/checkout/dziekujemy",
    component: _1bf54b99,
    name: "checkout-thankyou___pl-apteka"
  }, {
    path: "/checkout/payment-redirect",
    component: _1e8c0b67,
    name: "checkout-payment-redirect___pl-apteka"
  }, {
    path: "/checkout/platnosc-nie-powiodla-sie",
    component: _6d83b008,
    name: "checkout-payment-failed___pl-apteka"
  }, {
    path: "/order/cancelled",
    component: _6b4c1b8b,
    name: "order-cancelled___pl-apteka"
  }, {
    path: "/zapytaj-farmaceute/infolinia",
    component: _510c7e43,
    name: "askPharmacist-pharmacies___pl-apteka"
  }, {
    path: "/zapytaj-farmaceute/kontakt",
    component: _24028d05,
    name: "askPharmacist-callCenter___pl-apteka"
  }, {
    path: "/zapytaj-farmaceute/pytania",
    component: _c634f154,
    name: "askPharmacist-questionList___pl-apteka"
  }, {
    path: "/zapytaj-farmaceute/zadaj-pytanie",
    component: _68ec607c,
    name: "askPharmacist-questionForm___pl-apteka"
  }, {
    path: "/pharmacies/dermocenters/our-dermocenters",
    component: _3ef9471f,
    name: "pharmacies-dermocenters-our-dermocenters___pl-apteka"
  }, {
    path: "/pharmacies/dermocenters/social-networks",
    component: _74224362,
    name: "pharmacies-dermocenters-social-networks___pl-apteka"
  }, {
    path: "/",
    component: _45781064,
    name: "index___pl-apteka",
    children: []
  }, {
    path: "/pharmacies/dermocenters/:dermocenter",
    component: _42d40a9f,
    name: "pharmacies-dermocenters-dermocenter___pl-apteka"
  }, {
    path: "/apteki/:pharmacy",
    component: _18407904,
    name: "pharmacies-pharmacy___pl-apteka"
  }, {
    path: "/autorzy/:author",
    component: _3f61ff56,
    name: "authors-author___pl-apteka"
  }, {
    path: "/checkout/:step",
    component: _254a01b7,
    name: "checkout-step___pl-apteka"
  }, {
    path: "/repay/:hash?",
    component: _040cbcae,
    name: "repay-hash___pl-apteka"
  }, {
    path: "/tagi/:tag",
    component: _23b0df29,
    name: "tags-tag___pl-apteka",
    children: []
  }, {
    path: "/zapytaj-farmaceute/:question",
    component: _229fd283,
    name: "askPharmacist-question___pl-apteka"
  }, {
    path: "/:prefix?/promo",
    component: _7fe3d023,
    name: "promotion___pl-apteka"
  }, {
    path: "/:prefix?/search",
    component: _be9fffc4,
    name: "search___pl-apteka"
  }, {
    path: "/*",
    component: _242aae65,
    name: "all___pl-apteka"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
