import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  adultsOnlyEnabled: false,
  blogCategoryContentOnlyOnFirstPageEnabled: true,
  categoryTitleEnabled: true,
  infoBoxEnabled: true,
  mainPageTextEnabled: true,
  recentArticlesCategoriesEnabled: true,
  recentArticlesCategoriesHPEnabled: false,
  recentArticlesCategoriesIds: [55],
  relatedArticlesSimpleTitleEnabled: true,
  subcategoriesDescriptionOnBottomEnabled: true,
  ...config,
})
